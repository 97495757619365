import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import Layout from "../components/baseLayout"
import SEO from "../components/seo"
import Listing from "../components/Listing/listing"
import { FaAngleLeft } from 'react-icons/fa'
import { FaAngleRight } from 'react-icons/fa'


const BlogPage = ({
  pageContext: { currentPage, numPages },
  data: {
    posts: { edges },
  }
}) => {
  const isFirst = currentPage === 1  
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? "/articles" : `/articles/${(currentPage - 1).toString()}`
  const nextPage = `/articles/${(currentPage + 1).toString()}`

  return (
  <Layout>
    <SEO title={ "Articles" } />
        <div className="container archive_header page-header">
            <h1 className="page-title section_title title">Articles</h1>
        </div>
        <section className="container main_content_area blog_index_area flist_main_content_container">
            <h4 className="page-title screen-reader-text entry-title">Articles</h4>
            <div className="row full_width_list">
                <div className="col12">
                {edges.map((edge)=>{
                    const node = edge.node
                    const key = node.uid
                    const title = node.data.title.text
                    const date = node.data.release_date
                    const content = node.data.body
                    const category = node.data.category.document[0].data.cat_name.text
                    return (
                      <Listing key={key} title={title} content={content} date={date} category={category} uid={key}></Listing>
                    )
                })}
                    {/* loop article here */}
                    <nav className="navigation pagination" role="navigation">
                      <h2 className="screen-reader-text">Posts navigation</h2>
                      <div className="nav-links">
                        {!isFirst && <Link to={prevPage} className="prev page-numbers"><FaAngleLeft /></Link>}
                          { pageNav(currentPage, numPages).map(navElement=>(
                              navElement
                          )) }
                        {!isLast && <Link to={nextPage} className="next page-numbers"><FaAngleRight /></Link>}
                        
                      </div>
                    </nav>
                </div>

            </div>
        </section>
  </Layout>
)
}
const pageNav = (currentPage, numPages)=>{
  const pageNavElements = []
  const isFirst = currentPage === 1
  
  for (let i = 0; i < numPages; i++){
    const page = i + 1
    if (currentPage === page){
      pageNavElements.push(
        <span aria-current="page" className="page-numbers current" key={page}>{ page }</span>
      )
    }
    else if (isFirst){
      pageNavElements.push(
        <Link to="{`/article`}" className="page-numbers" key={page}>{ page }</Link>
      ) 
    }
    else {
      pageNavElements.push(
        <Link to={`/article/${ page }`} className="page-numbers" key={page}>{ page }</Link>
      )
    }
  }
  return pageNavElements

}

export default BlogPage

export const pageQuery = graphql`
query articlesQuery($skip: Int!, $limit: Int!) {
  posts: allPrismicBasicPos(
    sort:{fields:[data___release_date], order: DESC}
    limit: $limit
    skip: $skip
    ) {
    totalCount
    edges {
      node {
        uid
        data{
          title{
            text
          }
          body{
            __typename
            ... on PrismicBasicPosBodyText{
              primary{
                text{
                  raw{
                    type
                    text
                  }
                }
              }
            }
          }
          release_date
          category{
            document{
              data{
                cat_name{
                  text
                }
              }
            }
          }
        }
      }
    }
  }
}
`